import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Obfuscate from "react-obfuscate"

const NousContacter = props => {
  const [state, setState] = useState({
    msg: "",
    email: "",
    number: 0,
    isSent: false,
    captcha: false,
  })

  const handleUpdate = event => {
    if (event.target.name === "msg") {
      setState({ ...state, [event.target.name]: event.target.value.replace(/[^a-zA-Z0-9 ]/g, "") })
    } else {
      setState({ ...state, [event.target.name]: event.target.value })
    }
  }

  const handleSubmit = evt => {
    evt.preventDefault()

    const data = new FormData()
    data.append("msg", state.msg)
    data.append("email", state.email)
    data.append("site", process.env.SITE)

    var url = process.env.GATSBY_API_URL + "/index.php?controller=inscription"
    if (parseInt(state.number) === 5) {
      fetch(url, {
        method: "POST",
        body: data,
      }).then(response => {
        setState({ ...state, isSent: true, captcha: false })

        return response.text()
      })
    } else {
      setState({ ...state, captcha: true })
    }
  }

  return (
    <>
      <Layout banner={false} location={props.location}>
        <SEO title="Nous contacter" description="Demande d'informations" />
        <h1 style={{ marginTop: "6rem" }}>Service client - Contactez-nous</h1>
        <div className="container">
          <p>
            Nous sommes à votre écoute au{" "}
            <strong>
              <Obfuscate tel="03 89 06 00 93" />
            </strong>{" "}
            du lundi au vendredi 9h-18h et samedi 9h-12h.
          </p>
          <h2>Envoyer un message</h2>
          <div className="field" style={{ paddingBottom: "6rem" }}>
            <form onSubmit={handleSubmit}>
              <div className="columns">
                <div className="column is-4">
                  <div className="field">
                    <label className="label" htmlFor="email">
                      Email
                    </label>
                    <div className="control">
                      <input className="input" name="email" id="email" type="email" required placeholder="Votre adresse email" onChange={handleUpdate} />
                    </div>
                  </div>

                  <div className="field">
                    <label className="label" htmlFor="number">
                      Donner le nombre de lettres du mots &quot;CADRE&quot;
                    </label>
                    <i>exemple, pour &quot;FRANCE&quot; c&apos;est : 6</i>
                    <div className="control">
                      <input className="input" name="number" id="number" type="number" required onChange={handleUpdate} style={{ width: "20%" }} />
                      {state.captcha ? <i style={{ color: "red", marginLeft: "1rem" }}>le nombre est incorrect !</i> : ""}
                    </div>
                  </div>
                </div>

                <div className="column is-8">
                  <div className="field">
                    <label className="label" htmlFor="msg">
                      Message
                    </label>
                    <div className="control">
                      <textarea name="msg" id="msg" className="textarea" rows="10" required onChange={handleUpdate}></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <button className="button is-primary" data-cy="contact-send">
                    Envoyer
                  </button>
                </div>
              </div>
            </form>
          </div>

          {!state.isSent ? (
            ""
          ) : (
            <div className="notification is-success" style={{ marginBottom: "3.6rem", textAlign: "center", fontSize: "1.4rem" }}>
              le message a bien été envoyé
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

NousContacter.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default NousContacter
